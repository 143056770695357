<template>
  <div class="row m-b-20 align-items-end" :class="messageTypeClass">
    <div class="col-auto p-l-0" v-if="!isAdminSendMsg">
      <h5
        class="text-white d-flex align-items-center theme-bg2 justify-content-center"
      >
        U
      </h5>
    </div>
    <div :class="messageBlockClass">
      <div class="msg">
        <h6
          class="m-b-0"
          :class="messageTextColor"
          v-html="message.message"
        ></h6>
      </div>
    </div>
    <div class="col-auto p-r-0" v-if="isAdminSendMsg">
      <h5
        class="text-white d-flex align-items-center theme-bg justify-content-center"
      >
        A
      </h5>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "TicketMessageItem",
  props: {
    message: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters("auth", ["userId"]),
    isAdminSendMsg() {
      return !!this.message.users_id;
    },
    messageTextColor() {
      return this.isAdminSendMsg ? "text-white" : "";
    },
    messageBlockClass() {
      return this.isAdminSendMsg ? "col text-right" : "col ";
    },
    messageTypeClass() {
      return this.isAdminSendMsg ? "send-chat" : "received-chat";
    }
  }
};
</script>

<style scoped></style>
