<template>
  <div class="row">
    <div class="col-md-6 text-left">
      <i
        class="fas f-14 m-r-5"
        :class="{
          'fa-phone': ticketFormValue.ticket_form.type === 'numeric',
          'fa-angle-double-right':
            ticketFormValue.ticket_form.type !== 'numeric' &&
            showTextformValue(ticketFormValue, 'text'),
          'fa-calendar-check': showTextformValue(ticketFormValue, 'date'),
          'fa-image': showTextformValue(ticketFormValue, 'image')
        }"
      />
      {{ ticketFormValue.ticket_form.label }}
    </div>
    <div
      class="col-md-6 text-right"
      v-if="showTextformValue(ticketFormValue, 'text')"
    >
      <a
        v-if="externalLinkToVerify"
        :href="externalLinkToVerify"
        target="_blank"
        class="m-r-10"
      >
        Check from Abyster
        <span class="fas fa-external-link-alt m-r-5" />
      </a>
      <template v-if="is_form">
        <div class="input-group">
          <input
            class="form-control"
            :type="ticketFormValue.ticket_form.type"
            v-model="valeur"
            :placeholder="ticketFormValue.ticket_form.label"
          />
          <div class="input-group-append">
            <BaseButton
              :show-loading="true"
              :loading="loading"
              :button-class="`btn btn-${is_change ? 'secondary' : 'warning'}`"
              @click.prevent="updateFormValue"
            >
              <span
                v-if="!loading"
                :class="`fas fa-${is_change ? 'check' : 'times'}`"
              />
            </BaseButton>
          </div>
        </div>
      </template>
      <template v-else>
        <b>{{ ticketFormValue.valeur }}</b>
        <a
          class=" m-l-10"
          href="#"
          title="Mettre a jour la valeur"
          @click.prevent="updateFormValue"
        >
          <span class="fas fa-edit text-warning" />
        </a>
      </template>
    </div>
    <div
      class="col-md-6 text-right"
      v-if="showTextformValue(ticketFormValue, 'date')"
    >
      <b>{{ ticketFormValue.valeur | moment("Do MMMM YYYY à H:m:s") }}</b>
    </div>
    <div
      class="col-md-6 text-right"
      v-if="showTextformValue(ticketFormValue, 'image')"
    >
      <a class="text-info" :href="ticketFormValue.valeur" target="_blank">
        <span class="fas fa-external-link-alt m-r-5" /><b
          >Consulter l'image</b
        ></a
      >
    </div>
  </div>
</template>

<script>
import BaseButton from "../common/BaseButton";
export default {
  name: "TicketFormValueItem",
  components: { BaseButton },
  props: {
    ticketFormValue: {
      type: Object,
      required: true
    },
    refAbyster: {
      type: [String, Number],
      default: null
    }
  },

  data() {
    return {
      valeur: null,
      loading: false,
      is_form: false
    };
  },

  computed: {
    is_change() {
      return this.ticketFormValue.valeur !== this.valeur;
    },
    externalLinkToVerify() {
      if (
        this.refAbyster &&
        this.ticketFormValue.ticket_form.code === "reference"
      ) {
        return `https://bo.be-wallet.net/api/v1/payment/${this.refAbyster}/${this.ticketFormValue.valeur}/reclam`;
      } else return null;
    }
  },

  created() {
    this.valeur = this.ticketFormValue.valeur;
  },

  methods: {
    showTextformValue(ticketFormValue, type) {
      switch (type) {
        case "text":
          return (
            ticketFormValue.ticket_form.type !== "datetime" &&
            ticketFormValue.ticket_form.type !== "date" &&
            ticketFormValue.ticket_form.type !== "image"
          );
        case "date":
          return (
            ticketFormValue.ticket_form.type === "datetime" ||
            ticketFormValue.ticket_form.type === "date"
          );
        case "image":
          return ticketFormValue.ticket_form.type === "image";
        default:
          return false;
      }
    },
    updateFormValue() {
      if (this.is_form) {
        if (this.is_change) {
          this.loading = true;
          this.$store
            .dispatch("assistance/updateTicketFormValue", {
              ...this.ticketFormValue,
              valeur: this.valeur
            })
            .then(() => {
              this.loading = false;
              this.is_form = false;
            })
            .catch(() => {
              this.loading = false;
            });
        } else this.is_form = false;
      } else this.is_form = true;
    }
  }
};
</script>

<style scoped></style>
