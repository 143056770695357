<template>
  <div>
    <div class="card mb-2">
      <div class="card-header pb-2 pt-2 bg-light">
        <div class="row align-items-center">
          <div class="col">
            <h5>
              Liste des tickets actifs -
              <router-link
                v-if="ticketUser"
                :to="{
                  name: 'ticket.user.detail',
                  params: { ...$route.params, users_id: ticketUser.id }
                }"
                class="f-w-500 text-capitalize text-info"
                >{{ username }}
              </router-link>
            </h5>
          </div>
          <a
            href="#"
            class="col-auto text-dark shadow-1"
            @click.prevent="
              $router.push({
                name: 'ticket.list',
                params: { ...$route.params }
              })
            "
          >
            <span class="fas fa-arrow-left m-r-5"></span>
            Retour à la liste
          </a>
        </div>
      </div>
      <div class="card-block pt-3 pb-2" v-if="ticketUser">
        <div class="row align-items-center justify-content-center">
          <div class="col">
            <span class="f-w-500"> {{ ticketUserPresentation }} </span>
            <div
              class="alert alert-danger rounded pt-1 pb-1"
              v-if="ticketUser.desactived_at"
            >
              Compte désactivé depuis le :
              {{ ticketUser.desactived_at | moment("DD-MM-YYYY H:m:s") }}
              motif : <b>{{ ticketUser.motif }}</b>
            </div>
            <div
              class="alert alert-danger rounded pt-1 pb-1"
              v-if="!ticketUser.can_deposit"
            >
              Compte de cette utilisateur a été désactivé pour les dépôts. Trop
              de tickets dépôts non favorables
            </div>
          </div>
          <div class="col-4">
            <a
              v-if="isCurrentUserSuperAdmin"
              :href="showProfileUrl"
              target="_blank"
              class="btn btn-secondary shadow-2 text-uppercase btn-block"
            >
              <span class="fas fa-user-lock m-r-5"></span>
              Consulter le profil
            </a>
            <router-link
              v-else
              :to="{
                name: 'ticket.user.detail',
                params: { ...$route.params, users_id: ticketUser.id }
              }"
              class="btn btn-secondary shadow-2 text-uppercase btn-block"
            >
              <span class="feather icon-activity m-r-5"></span>
              Consulter le mouvement
            </router-link>
            <a
              href="#"
              @click.prevent="closeAllTickets"
              class="btn btn-danger text-uppercase btn-block"
              :class="userTickets.length > 1 ? '' : 'disabled'"
            >
              <i class="fas fa-times" />
              Clôturer tous les tickets
            </a>
          </div>
        </div>
      </div>
    </div>
    <BaseLoader v-if="loading" />
    <div class="accordion" id="accordionTicketList" v-if="!loading">
      <TicketDescription :ticket="ticket" :is-default="true" />
      <template v-if="userTickets">
        <TicketDescription
          v-for="ticketModel in userTickets"
          :key="ticketModel.id"
          :ticket="ticketModel"
        />
      </template>
    </div>
    <div class="bg-secondary" v-if="isDetailsOpen">
      <router-view />
    </div>
  </div>
</template>

<script>
import TicketDescription from "../../components/ticket/TicketDescription";
import { mapGetters, mapState } from "vuex";
import { confirmation } from "../../helpers/utils";
import { adminBaseUrl } from "../../helpers/constants";
export default {
  name: "TicketDetails",
  components: { TicketDescription },

  data() {
    return {
      loading: true
    };
  },

  computed: {
    ...mapState("assistance", ["ticket"]),
    ...mapGetters("assistance", ["userTickets"]),
    ...mapGetters("auth", ["isCurrentUserSuperAdmin"]),
    ticketUser() {
      if (this.ticket) {
        return this.ticket.user;
      } else return null;
    },
    username() {
      return this.ticketUser
        ? this.ticketUser.nom + " " + this.ticketUser.prenom
        : null;
    },
    ticketUserPresentation() {
      if (this.ticketUser) {
        return `${this.ticketUser.country.name} | +${this.ticketUser.country.calling_code} ${this.ticketUser.phone}
          | ${this.ticketUser.email}`;
      } else return null;
    },
    showProfileUrl() {
      if (this.ticketUser)
        return `${adminBaseUrl}/account/comptes-utilisateurs/details/${this.ticketUser.id}`;
      else return "#";
    },
    isDetailsOpen() {
      return this.$route.name === "ticket.detail.message";
    }
  },

  created() {
    this.$store
      .dispatch("assistance/getTicket", this.$route.params.reference)
      .then(() => {
        this.$store
          .dispatch("assistance/getUserTicketList", {
            user_id: this.ticket.users_id,
            ticket_id: this.ticket.id
          })
          .then(() => {
            this.loading = false;
          });
      })
      .catch(err => {
        this.$store.dispatch("notification/add", {
          type: "danger",
          message: err.message,
          is_toast: true
        });
        this.$router.push({ name: "ticket.list" });
      });
  },

  methods: {
    closeAllTickets() {
      confirmation(
        `Voulez-vous vraiment clôturer les ${this.userTickets.length +
          1} tickets ?`
      ).then(ok => {
        if (ok) {
          this.loading = true;
          this.$store
            .dispatch("assistance/closeAllTicketInList", {
              page: null,
              users_id: this.ticket.users_id
            })
            .then(() => {
              this.$router.push({ name: "ticket.list" });
              this.loading = false;
            })
            .catch(() => {
              this.loading = false;
            });
        }
      });
    }
  }
};
</script>

<style scoped></style>
