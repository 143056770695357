<template>
  <div class="row align-items-center">
    <div class="col pr-0">
      <select
        v-on="listeners"
        v-bind="$attrs"
        :value="value"
        :id="idSelectedRef"
        class="form-control"
        :class="{ 'is-invalid': isInvalid }"
      >
        <option :value="null" disabled>--Message prédéfini--</option>
        <option v-for="msg in savedMessages" :key="msg.id" :value="msg.message">
          {{ msg.label }}
        </option>
      </select>
    </div>
    <div class="col-auto pl-0">
      <router-link
        :to="{ name: 'ticket.detail.message', params: $route.params }"
        class="shadow-sm pt-2 pl-1 pr-1 pb-1 rounded"
      >
        <span class="feather icon-edit f-24"></span>
      </router-link>
    </div>
  </div>
</template>

<script>
import { formFieldMixin } from "../../mixins/formFieldMixin";

export default {
  name: "TypeTicketSavedMessageSelect",
  mixins: [formFieldMixin],
  props: {
    ticketId: {
      type: [Number, String],
      required: true
    },
    savedMessages: {
      type: Array,
      required: true
    }
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: this.updateValue
      };
    },
    idSelectedRef() {
      return `js-select-single-message-${this.ticketId}`;
    }
  },
  mounted() {
    this.timeout = setTimeout(() => {
      window
        .$(`#${this.idSelectedRef}`)
        .select2({ placeholder: "--Message prédéfini--" })
        .change(event => {
          this.$emit("input", event.target.value);
        });
    }, 200);
  },
  beforeDestroy() {
    clearTimeout(this.timeout);
  }
};
</script>

<style scoped></style>
