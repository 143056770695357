<template>
  <div class="card rounded chat-sanders pr-1">
    <div class="card-header borderless">
      <h5 class="text-white f-w-500">{{ ticket.reference }}</h5>
      <span class="badge badge-light pt-1 float-right">
        {{ ticket.messages.length }} msg
      </span>
    </div>
    <div class="card-block m-t-30 p-0">
      <div
        class="scroll-div ps ps--active-y chat-scroll"
        :id="`chat-scroll-${ticket.id}`"
      >
        <div style="padding:0 30px 35px 30px;">
          <TicketMessageItem
            v-for="message in ticket.messages"
            :key="message.id"
            :message="message"
          />
        </div>
        <div class="ps__rail-x" style="left: 0px; bottom: 0px;">
          <div
            class="ps__thumb-x"
            tabindex="0"
            style="left: 0px; width: 0px;"
          ></div>
        </div>
        <div class="ps__rail-y" style="top: 0px; height: 280px; right: 0px;">
          <div
            class="ps__thumb-y"
            tabindex="0"
            style="top: 0px; height: 234px;"
          ></div>
        </div>
      </div>
    </div>
    <div class="right-icon-control border-top">
      <div class="input-group input-group-button p-10">
        <textarea
          type="text"
          class="form-control border-0 text-muted"
          rows="3"
          v-model="message"
          :class="{
            'is-invalid': $v.message.$error
          }"
          @input="$v.message.$touch()"
          placeholder="Write your message"
        ></textarea>
        <div class="input-group-append">
          <button
            class="btn"
            :class="$v.$invalid ? 'text-secondary' : 'text-info'"
            type="button"
            :disabled="$v.$invalid"
            @click.prevent="submitMessageForm"
          >
            <BaseLoader :is-small="true" v-if="loading" />
            <i class="fas f-24 shadow-1 fa-paper-plane" v-if="!loading"></i>
          </button>
        </div>
      </div>
      <template v-if="$v.message.$error">
        <label class="small form-text text-danger f-12 pt-0 mt-0">
          <em v-if="!$v.message.required">Veuillez entrer le message </em>
        </label>
      </template>
    </div>
    <div class="card-footer p-2">
      <TypeTicketSavedMessageSelect
        :ticket-id="ticket.id"
        :saved-messages="savedMessages"
        v-model="selectedMessage"
      />
    </div>
  </div>
</template>

<script>
import TicketMessageItem from "./TicketMessageItem";
import { required } from "vuelidate/lib/validators";
import BaseLoader from "../common/BaseLoader";
import { mapGetters } from "vuex";
import TypeTicketSavedMessageSelect from "./TypeTicketSavedMessageSelect";
import Vue from "vue";

export default {
  name: "TicketChatBox",
  components: { TypeTicketSavedMessageSelect, BaseLoader, TicketMessageItem },
  props: {
    ticket: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      loading: false,
      message: null,
      selectedMessage: null
    };
  },

  watch: {
    selectedMessage(value) {
      if (value) this.message = value;
    },
    message(value) {
      if (value !== this.selectedMessage) this.selectedMessage = null;
    }
  },

  computed: {
    ...mapGetters("assistance", ["getTypeTicketSavedMessages"]),
    savedMessages() {
      return this.getTypeTicketSavedMessages(this.ticket.type_tickets_id);
    }
  },
  created() {
    // eslint-disable-next-line no-undef
    (async () => {
      await Vue.loadScript(
        `${window.location.origin}/assets/plugins/select2/js/select2.full.min.js`
      );
    })();
  },
  mounted() {
    new window.PerfectScrollbar(`#chat-scroll-${this.ticket.id}`, {
      wheelSpeed: 0.5,
      swipeEasing: 0,
      suppressScrollX: !0,
      wheelPropagation: 1,
      minScrollbarLength: 40
    });
  },

  methods: {
    submitMessageForm() {
      if (!this.$v.invalid) {
        this.loading = true;
        this.$store
          .dispatch("assistance/sendNewMessage", {
            message: this.message,
            ticket: this.ticket
          })
          .then(() => {
            this.message = null;
            this.loading = false;
          });
      }
    }
  },

  validations() {
    return {
      message: { required }
    };
  }
};
</script>

<style>
.chat-scroll {
  height: 270px;
  position: relative;
}
</style>
