<template>
  <div class="card mt-1 mb-1">
    <div class="card-header" :id="`headingOne-${ticket.id}`">
      <h5 class="mb-0">
        <a
          href="#"
          :class="collapsedClass"
          class="text-muted"
          data-toggle="collapse"
          :data-target="`#collapse-${ticket.id}`"
          aria-expanded="true"
          :aria-controls="`collapse-${ticket.id}`"
        >
          <span class="f-w-500 text-dark">{{ ticket.reference }} -</span>
          {{ ticketLabel }} - {{ ticket.created_at | moment("DD MMMM YYYY") }}
        </a>
      </h5>
      <div class="float-right">
        <BaseButton
          type="button"
          :loading="loading"
          button-class="btn-warning m-0"
          :show-loading="loading"
          @click.prevent="closeTicket"
        >
          <span class="feather icon-toggle-right m-r-5" />
          Clôturer
        </BaseButton>
      </div>
    </div>
    <div
      :id="`collapse-${ticket.id}`"
      class=" card-body p-2 bg-light collapse"
      :class="collapsedContentClass"
      :aria-labelledby="`headingOne-${ticket.id}`"
      data-parent="#accordionTicketList"
    >
      <div class="row">
        <div class="col-md-8 pr-0">
          <TicketItem
            :ticket="ticket"
            :key="`item-${ticket.id}`"
            @closeTicket="confirmCloseTicket"
          />
        </div>
        <div class="col-md-4 pl-0">
          <TicketChatBox :ticket="ticket" :key="`chat-${ticket.id}`" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TicketChatBox from "./TicketChatBox";
import TicketItem from "./TicketItem";
import BaseButton from "../common/BaseButton";
import { ticketMixin } from "../../mixins/ticketMixin";
import { mapGetters } from "vuex";
import { confirmation } from "../../helpers/utils";
export default {
  name: "TicketDescription",
  components: { BaseButton, TicketItem, TicketChatBox },
  mixins: [ticketMixin],
  props: {
    isDefault: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false
    };
  },
  computed: {
    ...mapGetters("assistance", ["userTickets"]),

    collapsedClass() {
      return this.isDefault ? "" : "collapsed";
    },
    collapsedContentClass() {
      return this.isDefault ? "show" : "";
    }
  },

  methods: {
    closeTicket() {
      confirmation(
        `Voulez-vous vraiment clôturer le ticket ${this.ticket.reference} ?`
      ).then(ok => {
        if (ok) {
          this.loading = false;
          this.$store
            .dispatch("assistance/closeTicket", this.ticket)
            .then(() => {
              this.confirmCloseTicket();
              this.loading = false;
            })
            .catch(() => {
              this.loading = false;
            });
        }
      });
    },
    confirmCloseTicket() {
      if (this.userTickets.length > 0 && this.$route.params.reference) {
        const ticket = this.userTickets[0];
        if (
          ticket.reference.toLowerCase() !==
          this.$route.params.reference.toLowerCase()
        )
          this.$router.push({
            name: "ticket.detail",
            params: { reference: ticket.reference.toLowerCase() }
          });
      } else {
        if (!this.ticket.is_active)
          this.$router.push({
            name: "ticket.list",
            params: { ...this.$route.params }
          });
      }
    }
  }
};
</script>

<style scoped></style>
