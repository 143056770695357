import { mapGetters } from "vuex";
import { statusValues, typeTicketCode } from "../helpers/constants";

export const ticketMixin = {
  props: {
    ticket: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false
    };
  },
  computed: {
    ...mapGetters("auth", ["country_id", "userId", "isCurrentUserSuperAdmin"]),
    username() {
      return this.ticket.user.nom + " " + this.ticket.user.prenom;
    },
    canShowAmount() {
      return (
        this.ticket.model && this.ticket.type.code !== typeTicketCode.number
      );
    },
    isForNumber() {
      return (
        this.ticket.model && this.ticket.type.code === typeTicketCode.number
      );
    },
    operationAmount() {
      let amount = this.ticket.model.base_amount || this.ticket.model.amount;
      if (this.ticket.type.code === typeTicketCode.withdraw) {
        amount =
          this.ticket.model.amount -
          (
            this.ticket.model.amount -
            (this.ticket.model.amount * 100) / (100 + this.ticket.model.frais)
          ).toFixed(0);
      }
      if (this.ticket.type.code === typeTicketCode.number)
        return this.ticket.model.phone;
      return `${amount} ${this.ticket.model.currency}`;
    },
    activeClass() {
      switch (this.ticket.model.status) {
        case statusValues.success:
          return "success";
        case statusValues.error:
          return "danger";
        case statusValues.pending:
          return "info";
        default:
          return "secondary";
      }
    },
    operatorName() {
      if (this.ticket.info) {
        if (this.ticket.info.service) return this.ticket.info.service.label;
        if (this.ticket.info.operator) return this.ticket.info.operator.label;
      }
      if (this.ticket.type.code === typeTicketCode.number)
        return this.ticket.operator.label;
      return null;
    },
    ticketLabel() {
      let label = this.ticket.type.label;
      if (this.ticket.model) {
        label += " - " + this.operationAmount;
        if (this.operatorName) label += " - " + this.operatorName;
        if (this.ticket.model.status) label += " - " + this.ticket.model.status;
      }
      return label;
    }
  },

  methods: {
    verifyTicketOperation() {
      this.loading = true;
      this.$store
        .dispatch("assistance/verifyTicketOperationState", this.ticket)
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    verifyTicketNumber() {
      this.loading = true;
      this.$store
        .dispatch("assistance/confirmTicketNumber", this.ticket)
        .then(() => {
          this.loading = false;
          this.$emit("closeTicket");
        })
        .catch(() => {
          this.loading = false;
        });
    }
  }
};
