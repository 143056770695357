<template>
  <div class="card-block p-0 pb-2 pl-1 pr-1">
    <div class="border pl-3 pr-3 pt-0 rounded">
      <div class="row justify-content-center">
        <div class="col-md-12 p-0">
          <ul class="list-group p-0 shadow-sm">
            <li class="list-group-item" v-if="ticket.label !== null">
              <div class="row">
                <div class="col-md-4 text-left">
                  <i class="fas f-14 fa-calendar-check m-r-5" />
                  Sujet du ticket
                </div>
                <div class="col-md-8 text-right">
                  {{ ticket.label }}
                </div>
              </div>
            </li>
            <li class="list-group-item">
              <div class="row">
                <div class="col-md-6 text-left">
                  <i class="fas f-14 fa-anchor m-r-5" />
                  Type de ticket
                </div>
                <div class="col-md-6 text-right">
                  {{ ticket.type.label }}
                </div>
              </div>
            </li>
            <li class="list-group-item" v-if="serviceName">
              <div class="row">
                <div class="col-md-4 text-left">
                  <i class="fas f-14 fa-credit-card m-r-5" />
                  Service concerné
                </div>
                <div class="col-md-8 text-right">
                  {{ serviceName }}
                </div>
              </div>
            </li>
            <li class="list-group-item">
              <div class="row">
                <div class="col-md-6 text-left">
                  <i class="fas f-14 fa-calendar m-r-5" />
                  Ouvert depuis le
                </div>
                <div class="col-md-6 text-right">
                  {{ ticket.created_at | moment("Do/MM/YYYY H:m:s") }}
                </div>
              </div>
            </li>
            <li class="list-group-item">
              <div class="row">
                <div class="col-md-6 text-left">
                  <i class="fas f-14 fa-calendar-alt m-r-5" />
                  Mise à jour le
                </div>
                <div class="col-md-6 text-right">
                  {{ ticket.updated_at | moment("Do/MM/YYYY H:m:s") }}
                </div>
              </div>
            </li>
            <li class="list-group-item" v-if="ticket.performed_at">
              <div class="row">
                <div class="col-md-6 text-left">
                  <i class="fas f-14 fa-calendar-check m-r-5" />
                  Opération exécuté le
                </div>
                <div class="col-md-6 text-right">
                  {{ ticket.performed_at | moment("Do MMMM YYYY à H:m:s") }}
                </div>
              </div>
            </li>
            <li class="list-group-item" v-if="ticket.amount">
              <div class="row">
                <div class="col-md-6 text-left">
                  <i class="fas f-14 fa-dollar-sign m-r-5" />
                  Montant de l'opération
                </div>
                <div class="col-md-6 text-right text-info">
                  {{ ticket.amount + " CFA" }}
                </div>
              </div>
            </li>
            <li class="list-group-item" v-if="ticket.capture">
              <div class="row">
                <div class="col-md-6 text-left">
                  <i class="fas f-14 fa-image m-r-5" />
                  Capture du SMS de l'opération
                </div>
                <div class="col-md-6 text-right text-info">
                  <a class="text-info" :href="ticket.capture" target="_blank">
                    <span class="fas fa-external-link-alt m-r-5" /><b
                      >Consulter l'image</b
                    ></a
                  >
                </div>
              </div>
            </li>
            <li
              class="list-group-item text-center pb-2 text-dark"
              title="Description du ticket"
            >
              <span v-html="ticket.description" />
            </li>
            <template v-if="ticket.ticket_form_values.length > 0">
              <div
                class="border text-center text-muted text-uppercase f-18 shadow-sm"
              >
                Informations renseignées par l'utilisateur
              </div>
              <li
                class="list-group-item"
                v-for="ticketFormValue in ticket.ticket_form_values"
                :key="ticketFormValue.id"
              >
                <TicketFormValueItem
                  :ticketFormValue="ticketFormValue"
                  :ref-abyster="refAbyster"
                />
              </li>
            </template>
            <template v-if="ticket.model !== null && !isForNumber">
              <div
                class="border text-center text-muted text-uppercase f-18 shadow-sm"
              >
                Opération concernée par la reclamation
              </div>
              <li class="list-group-item" v-if="gateway">
                <div class="row">
                  <div class="col-md-6 text-left">
                    <i class="fas f-14 fa-phone m-r-5" />
                    Passerelle utilisé
                  </div>
                  <div class="col-md-6 text-right">
                    {{ gateway }}
                  </div>
                </div>
              </li>
              <li class="list-group-item" v-if="refAbyster">
                <div class="row">
                  <div class="col-md-6 text-left">
                    <i class="fas f-14 fa-code m-r-5" />
                    Réference ABYSTER
                  </div>
                  <div class="col-md-6 text-right">
                    {{ refAbyster }}
                  </div>
                </div>
              </li>
              <li class="list-group-item">
                <div class="row">
                  <div class="col-md-6 text-left">
                    <i class="fas f-14 fa-calendar-check m-r-5" />
                    Dernière modification
                  </div>
                  <div class="col-md-6 text-danger text-right">
                    {{ ticket.model.created_at | moment("Do/MM/YYYY H:m:s") }}
                  </div>
                </div>
              </li>
              <li class="list-group-item">
                <div class="row">
                  <div class="col-md-6 text-left">
                    <i class="fas f-14 fa-dollar-sign m-r-5" />
                    Montant de l'opération
                  </div>
                  <div class="col-md-6 text-right text-info">
                    {{ operationAmount }}
                  </div>
                </div>
              </li>
              <li class="list-group-item">
                <div class="row">
                  <div class="col-md-6 text-left">
                    <i class="fas f-14 fa-code m-r-5" />
                    Réference de l'opération
                  </div>
                  <div class="col-md-6 text-right">
                    {{ operationReference }}
                  </div>
                </div>
              </li>
              <li
                class="list-group-item"
                v-if="isNotPaymentTicket && ticket.info && userUsedId"
              >
                <div class="row">
                  <div class="col-md-6 text-left">
                    <i class="fas f-14 fa-phone m-r-5" />
                    Identifiant utilisateur
                  </div>
                  <div class="col-md-6 text-right">
                    {{ userUsedId | truncate(64) }}
                  </div>
                </div>
              </li>
              <li class="list-group-item">
                <div class="row">
                  <div class="col-md-6 text-left">
                    <i class="fas f-14 fa-eye m-r-5" />
                    Statut de l'opération
                  </div>
                  <div class="col-md-6 text-right">
                    <span
                      class="badge f-12 p-1"
                      :class="ticketModelStatusClass"
                      >{{ ticket.model.status }}</span
                    >
                  </div>
                </div>
              </li>
              <li
                class="list-group-item text-center text-dark p-1 bg-c-yellow"
                v-if="isValidationWithdraw"
              >
                <i class="fas f-14 fa-info-circle m-r-10" />
                Le retrait est en cours de validation
              </li>
              <li class="list-group-item text-center">
                <BaseButton
                  :loading="loading"
                  :show-loading="loading"
                  type="button"
                  button-class="btn-primary"
                  @click.prevent="verifyTicketOperation"
                >
                  <span
                    class="feather icon-refresh-ccw m-r-5"
                    v-if="!loading"
                  />
                  Vérifier le statut de l'opération
                </BaseButton>
              </li>
            </template>
            <template v-if="isForNumber">
              <li class="list-group-item">
                <div class="row">
                  <div class="col-md-6 text-left">
                    <i class="fas f-14 fa-phone m-r-5" />
                    Identifiant utilisateur
                  </div>
                  <div class="col-md-6 text-right">
                    {{ ticket.model.phone }}
                  </div>
                </div>
              </li>
              <li class="list-group-item text-center">
                <BaseButton
                  :loading="loading"
                  :show-loading="loading"
                  type="button"
                  button-class="btn-primary"
                  @click.prevent="verifyTicketNumber"
                >
                  <span
                    class="feather icon-check-circle m-r-5"
                    v-if="!loading"
                  />
                  Valider le numéro de téléphone
                </BaseButton>
              </li>
            </template>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TicketFormValueItem from "./TicketFormValueItem";
import { statusValues, typeTicketCode } from "../../helpers/constants";
import { ticketMixin } from "../../mixins/ticketMixin";
import BaseButton from "../common/BaseButton";
export default {
  name: "TicketItem",
  components: { BaseButton, TicketFormValueItem },
  mixins: [ticketMixin],
  computed: {
    gateway() {
      if (this.ticket.model.api_code === "APIEXT04") {
        return this.ticket.model.signature
          ? this.ticket.model.signature
          : this.ticket.model.client_reference
          ? this.ticket.model.client_reference
          : null;
      } else return null;
    },

    refAbyster() {
      if (this.ticket.model.api_code === "APIEXT04") {
        return this.ticket.model.op_reference;
      } else return null;
    },

    operationReference() {
      switch (this.ticket.type.code) {
        case typeTicketCode.deposit:
          return this.ticket.model.reference;
        case typeTicketCode.withdraw:
          return this.ticket.model.client_transaction_id;
        case typeTicketCode.payment:
          return this.ticket.model.payment_id;
        default:
          return this.ticket.model.reference;
      }
    },

    isNotPaymentTicket() {
      return this.ticket.type.code !== typeTicketCode.payment;
    },

    userUsedId() {
      switch (this.ticket.type.code) {
        case typeTicketCode.deposit:
          return this.ticket.model.phone;
        case typeTicketCode.withdraw:
          return `+${this.ticket.info.operator.country.calling_code} ${this.ticket.info.phone}`;
        default:
          return this.ticket.info.reference;
      }
    },

    isValidationWithdraw() {
      try {
        if (this.ticket.model.status === statusValues.pending)
          return (
            this.ticket.model.api_code === null &&
            this.ticket.model.is_validated !== undefined &&
            !this.ticket.model.is_validated
          );
        else return false;
      } catch (e) {
        alert("Erreur contactez Tcharod");
        return false;
      }
    },
    ticketModelStatusClass() {
      switch (this.ticket.model.status) {
        case statusValues.success:
          return "badge-success";
        case statusValues.error:
          return "badge-danger";
        default:
          return "badge-info";
      }
    },
    serviceName() {
      if (this.ticket.info) {
        if (this.ticket.info.service)
          return `${this.ticket.info.service.type_service.label} | ${this.ticket.info.service.label}`;
        if (this.ticket.info.operator) return this.ticket.info.operator.label;
        if (this.ticket.info.label) return this.ticket.info.label;
        else return null;
      } else return null;
    }
  }
};
</script>

<style scoped></style>
